import router from './router'
import NProgress from 'nprogress'       // Progress 进度条
import 'nprogress/nprogress.css'        // Progress 进度条样式
import {getToken} from '@/utils/auth' // 验权

const whiteList = ['/login','/hanger']            // 不重定向白名单
router.beforeEach((to, from, next) => {
    // 修改标题
    document.title = to.meta.title;
    NProgress.start()
    if (getToken()) {
        if (to.path === '/login') {
            next({ path: '/' })
            NProgress.done() // if current page is dashboard will not trigger	afterEach hook, so manually handle it
        } else {
            // } else {
                next();
            // }

            //

        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            next();
            NProgress.done()
        }
    }
});
router.afterEach(() => {
    NProgress.done() // 结束Progress
})

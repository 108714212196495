import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        path: '/login',
        name: '碳资产管理SAAS平台登录',
        meta: {title: '碳资产管理SAAS平台登录'},
        component: () => import('@/views/login/LoginView.vue')
    },
    {
        path: '/hanger',
        name: '钩子',
        meta: {title: '钩子'},
        component: () => import('@/views/login/HangerView.vue')
    },
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/',
        name: '首页',
        component: () => import('@/views/layout/LayoutView.vue'),
        meta: { title: '首页' },
        children: [
            {
                path: 'home',
                name: '/',
                component: () => import('@/views/home/IndexView.vue'),
                meta: { title: '首页', topMenu: '/home' },
            }
        ]
    },
    {
        path: '/product',
        name: '产品',
        component: () => import('@/views/layout/LayoutView.vue'),
        meta: { title: '产品' },
        children: [
            {
                path: 'buy',
                name: '/product/buy',
                component: () => import('@/views/pay/PayView.vue'),
                meta: { title: '产品', topMenu: '/buy' },
            },
            {
                path: 'payres',
                name: '/product/payres',
                component: () => import('@/views/pay/PayRes.vue'),
                meta: { title: '购买结果', topMenu: '/buy' },
            }
        ]
    },
    {
        path: '/orders',
        name: '订单管理',
        component: () => import('@/views/layout/LayoutView.vue'),
        meta: { title: '订单管理' },
        children: [
            {
                path: 'ordercenter',
                name: '/orders/ordercenter',
                component: () => import('@/views/orders/OrderCenter.vue'),
                meta: { title: '订单管理', topMenu: '/orders/ordercenter' },
            },
        ]
    },
    {
        path: '/openapi/doc',
        redirect: '/openapi/doc/1-1'
    },
    {
        path: '/openapi',
        name: '开放平台',
        component: () => import('@/views/layout/LayoutViewNoFoot.vue'),
        meta: { title: '开放平台' },
        children: [
            {
                path: 'doc',
                name: '/openapi/doc',
                component: () => import('@/views/openapi/DocView.vue'),
                meta: { title: '帮助文档', topMenu: '/openapi/doc' },
                children: [
                    {
                        path: '1-1',
                        name: '/openapi/doc/1-1',
                        component: () => import('@/views/openapi/docCom/page1.vue'),
                        meta: { title: '帮助文档', topMenu: '/openapi/doc' },
                    },
                    {
                        path: '1-2',
                        name: '/openapi/doc/1-2',
                        component: () => import('@/views/openapi/docCom/page1.vue'),
                        meta: { title: '帮助文档', topMenu: '/openapi/doc' },
                    },
                    {
                        path: '2-1',
                        name: '/openapi/doc/2-1',
                        component: () => import('@/views/openapi/docCom/page2.vue'),
                        meta: { title: '帮助文档', topMenu: '/openapi/doc' },
                    },
                    {
                        path: '3-1',
                        name: '/openapi/doc/3-1',
                        component: () => import('@/views/openapi/docCom/page3-1.vue'),
                        meta: { title: '帮助文档', topMenu: '/openapi/doc' },
                    },
                    {
                        path: '3-2',
                        name: '/openapi/doc/3-2',
                        component: () => import('@/views/openapi/docCom/page3-2.vue'),
                        meta: { title: '帮助文档', topMenu: '/openapi/doc' },
                    },
                    {
                        path: '3-3',
                        name: '/openapi/doc/3-3',
                        component: () => import('@/views/openapi/docCom/page3-3.vue'),
                        meta: { title: '帮助文档', topMenu: '/openapi/doc' },
                    },
                    {
                        path: '3-4',
                        name: '/openapi/doc/3-4',
                        component: () => import('@/views/openapi/docCom/page3-4.vue'),
                        meta: { title: '帮助文档', topMenu: '/openapi/doc' },
                    },
                    {
                        path: '4-1',
                        name: '/openapi/doc/4-1',
                        component: () => import('@/views/openapi/docCom/page4.vue'),
                        meta: { title: '帮助文档', topMenu: '/openapi/doc' },
                    },
                    {
                        path: '5-1',
                        name: '/openapi/doc/5-1',
                        component: () => import('@/views/openapi/docCom/page5.vue'),
                        meta: { title: '帮助文档', topMenu: '/openapi/doc' },
                    },
                    {
                        path: '6-1',
                        name: '/openapi/doc/6-1',
                        component: () => import('@/views/openapi/docCom/page6.vue'),
                        meta: { title: '帮助文档', topMenu: '/openapi/doc' },
                    },
                    {
                        path: '7-1',
                        name: '/openapi/doc/7-1',
                        component: () => import('@/views/openapi/docCom/page7.vue'),
                        meta: { title: '帮助文档', topMenu: '/openapi/doc' },
                    },
                    {
                        path: '8-1',
                        name: '/openapi/doc/8-1',
                        component: () => import('@/views/openapi/docCom/page8.vue'),
                        meta: { title: '帮助文档', topMenu: '/openapi/doc' },
                    }
                ]
            },
            {
                path: 'manager',
                name: '/openapi/manager',
                component: () => import('@/views/openapi/OpenApiView.vue'),
                meta: { title: '帮助文档', topMenu: '/openapi/manager' },
                children: [
                    {
                        path: '1-1',
                        name: '/openapi/manager/1-1',
                        component: () => import('@/views/openapi/AppIdView.vue'),
                        meta: { title: '帮助文档', topMenu: '/openapi/manager' },
                    },
                    {
                        path: '2-1',
                        name: '/openapi/manager/2-1',
                        component: () => import('@/views/openapi/WhiteListView.vue'),
                        meta: { title: '白名单管理', topMenu: '/openapi/manager' },
                    },
                ]
            },
        ]
    },
    {
        path: '/system',
        name: '系统管理',
        component: () => import('@/views/layout/LayoutView.vue'),
        meta: { title: '系统管理' },
        children: [
            {
                path: 'company',
                name: '/system/company',
                component: () => import('@/views/orders/OrderCenter.vue'),
                meta: { title: '机构管理', topMenu: '/system/company' },
            },
            {
                path: 'companyedit',
                name: '/system/companyedit',
                component: () => import('@/views/system/CompanyEditView.vue'),
                meta: { title: '机构管理编辑', topMenu: '/system/company' },
            },
            {
                path: 'user',
                name: '/system/user',
                component: () => import('@/views/system/User.vue'),
                meta: { title: '用户管理', topMenu: '/system/company' },
            },
            {
                path: 'useredit',
                name: '/system/useredit',
                component: () => import('@/views/system/UserEditView.vue'),
                meta: { title: '用户管理编辑', topMenu: '/system/company' },
            },
            {
                path: 'sysrole',
                name: '/system/sysrole',
                component: () => import('@/views/system/SysRoleView.vue'),
                meta: { title: '角色管理', topMenu: '/system/company' },
            },
            {
                path: 'sysroleedit',
                name: '/system/sysroleedit',
                component: () => import('@/views/system/SysRoleEditView'),
                meta: { title: '角色编辑', topMenu: '/system/company' },
            },
            {
                path: 'usersetting',
                name: '/system/usersetting',
                component: () => import('@/views/system/UserSettingView.vue'),
                meta: { title: '用户管理设置', topMenu: '/system/company' },
            },
            {
                path: 'sysconfig',
                name: '/system/sysconfig',
                component: () => import('@/views/pay/PayView.vue'),
                meta: { title: '系统配置', topMenu: '/system/sysconfig' },
            },
        ]
    },
    {
        path: '*',
        name: '404',
        component: () => import('@/views/Error.vue'),
        meta: { title: '404' },
    },
]

const router = new VueRouter({
    routes
})

export default router

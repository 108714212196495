import './config'
import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';          // ElementUI 基础样式
import 'element-ui/lib/theme-chalk/display.css';        // ElementUI 响应式隐藏样式
import hasPermission from './utils/hasPermission'

import print from 'vue-print-nb';//引入下载的print
import BigDecimal from 'js-big-decimal'

import '@fortawesome/fontawesome-free/css/all.min.css'; // 免费图标字体
import '@/permission';                                   // permission control
import { Graph } from '@antv/x6';

import router from './router';
import store from './store';

import 'swiper/dist/css/swiper.min.css';
import 'swiper/dist/js/swiper.min';
import 'github-markdown-css/github-markdown.css'

Vue.config.productionTip = false
Vue.use(ElementUI);
// 全局使用
Vue.use(print);
Vue.use(hasPermission)
Vue.prototype.BigDecimal = BigDecimal
// Vue.component('vue-draggable-resizable', VueDraggableResizable);

new Vue({
    router,
    store,
    data: function () {
        return {
            menuUrl: [],
            leftActive: ['/home', '首页']
        }
    },
    render: h => h(App)
}).$mount('#app')
